.dashboard {
    width: 100vw;
    height: 100vh;
    background-color: #F0F0F0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .dashboard-content {
        width: 100vw;
        display: flex;        
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 10px;
       

        .dashboard-contentTop {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 95%;
            margin-bottom: 10px;
         
            .dashboard-contentTopleft{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 30vh;
                width: 100%;
             
               
                
            }
            .dashboard-contentTopleftup{
                box-sizing: border-box;
               // padding-right: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                height:45%; 
               
                            
            }

            .dashboard-contentTopleftdown {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                height: 45%;
               
            }

            .dashboard-contentTopRight {
                background-color: #fff;
                width:20vw;
                height: 30vh;
                border-radius: 15px;
                box-shadow: 0px 0px 16px #00000029;
                margin-left: 10px;
            }
        }
        .dashboard-contentBottom{            
           box-sizing: border-box;
           padding: 20px 0 0 10px;
            width: 95%;            
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;       
        }

    }
}