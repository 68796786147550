@import '../../Styles/variables';

.DashboardCardSubscribers {
    box-sizing: border-box;
    width: 47.5%;
    height:100%;
    background-color: #FF8300;
    box-shadow: 0px 0px 16px #00000029;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 50px;
  
  

    .DashboardCardSubscribers-text {
        font-size: 1.2rem;
        color: #fff;
        font-family: $--primary-font;
        margin: 5px
    }

    .DashboardCardSubscribers-value {
        font-size: 1.9rem;
        color: #fff;
        font-family: $--primary-font;
        margin: 5px
    }

   
}