@import '../../Styles/variables';
.dashboardChart{
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 5%;
    
   
    .dashboardChart-title{
        padding-left: 15px;
        color: #3C3C3C;       
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        font-size: 1rem;
        font-family: $--primary-font;
        border-bottom: 1px solid #e6e6e6fd;
        margin-bottom: 10px;
    }
      
  
}


  .dashboardChart-chart{
  
   height: 13.5vw !important;
   width:  13.5vw !important;
   max-height: 90% !important;
   max-width: 100% !important;
  }