@import '../../Styles/variables';
.login{
    background-color: #F0F0F0;
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
    .login-left{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50vw;
        height: 100vh;
        color: #fff;
        font-family: $--primary-font;
        background:url('../../img/bg-dashboard.png');
        background-position: center;
        background-size: cover;
        .login-bgleft{
            width: 90%;
        }
    }
    .login-right{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50vw;
        height: 100vh;
        .login-title{
            font-size: 1.4rem;
            color: #3C3C3C;
            font-family: $--primary-font;
        }
       
        .login-nameinput,.login-passwordinput{
            width: 65%;
            margin-bottom: 30px;    
            
          
              & ::before{
                 border-color: #3C3C3C;               
              }            
        }
        .login-error{
            color: red;
            font-family: $--primary-font;
            font-size: .8rem;
        }
        .login-submit{
            padding: 0 25px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 20px;
            width: 200px;
            height: 48px;
            background: #3C3C3C;
            border-radius: 14px;
            color: #fff;
            font-size: .8rem;
            font-family: $--primary-font;
            font-weight: bold;
            cursor: pointer;
            border: none !important;

            img{                
                width: 10px;
            }
        }
    }
}