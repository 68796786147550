@import '../../Styles/variables';

.dashboardRankGeral {
    font-family: $--primary-font;
    color: #3C3C3C;
    width: 32%;
    height: 48vh;
    background-color: #fff;
    box-shadow: 0px 0px 16px #00000029;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;

    .dashboardRankGeral-titleArea {
        font-size: 1rem;
        display: flex;
        padding-left: 30px;
 
    }

    .dashboardRankGeral-headerArea {
        font-family: $--primary-font;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 35px;
        align-items: center;
        padding: 0 15px 0 15px;
        background-color: #FBFBFB;
        border-top: 1px solid rgb(196, 196, 196);

        .dashboardRankGeral-headerPosition {
            width: 10%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
        }

        .dashboardRankGeral-headerName,
        .dashboardRankGeral-headerCoins {
            width: 19%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
        }
       

        .dashboardRankGeral-headerUnity {
            width: 35%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
        }
    }

    .dashboardRankGeral-contentArea {
        height: 72%;      
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

#sentinel {
    background-color: red;
    width: 100%;
    height: 5px;
}