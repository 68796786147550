@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,900;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(70, 70, 70); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}
.dashboard{width:100vw;height:100vh;background-color:#f0f0f0;display:flex;justify-content:flex-start;flex-direction:column}.dashboard .dashboard-content{width:100vw;display:flex;justify-content:center;align-items:center;flex-direction:column;padding-top:10px}.dashboard .dashboard-content .dashboard-contentTop{display:flex;flex-direction:row;align-items:flex-end;width:95%;margin-bottom:10px}.dashboard .dashboard-content .dashboard-contentTop .dashboard-contentTopleft{display:flex;flex-direction:column;justify-content:space-between;height:30vh;width:100%}.dashboard .dashboard-content .dashboard-contentTop .dashboard-contentTopleftup{box-sizing:border-box;display:flex;flex-direction:row;justify-content:space-between;width:100%;height:45%}.dashboard .dashboard-content .dashboard-contentTop .dashboard-contentTopleftdown{display:flex;flex-direction:row;justify-content:space-between;width:100%;height:45%}.dashboard .dashboard-content .dashboard-contentTop .dashboard-contentTopRight{background-color:#fff;width:20vw;height:30vh;border-radius:15px;box-shadow:0px 0px 16px rgba(0,0,0,.1607843137);margin-left:10px}.dashboard .dashboard-content .dashboard-contentBottom{box-sizing:border-box;padding:20px 0 0 10px;width:95%;display:flex;flex-direction:row;justify-content:space-between;align-items:center}
.dashboardNav{box-sizing:border-box;display:flex;width:100vw;height:80px;background-color:#fff;justify-content:space-between;align-items:center;box-shadow:0px 3px 20px rgba(0,0,0,.0823529412);padding:0 0 0 20px;margin-bottom:20px}.dashboardNav .dashboardNav-contentLeft{padding:0 20px 0 0;height:100%;display:flex;width:80%;justify-content:space-between;align-items:center}.dashboardNav .dashboardNav-contentLeft .dashboardNav-logoArea{display:flex;justify-self:center;align-items:center}.dashboardNav .dashboardNav-contentLeft .dashboardNav-logo{width:110px;margin:0 10px}.dashboardNav .dashboardNav-contentLeft .dashboardNav-title{font-family:"Montserrat",sans-serif;font-weight:bold;font-size:1.5rem;color:#3d5170;margin:0 10px}.dashboardNav .dashboardNav-contentRight{display:flex;align-items:center;justify-content:center;width:15vw;height:100%;border-left:1px solid #8c98ab}.dashboardNav .dashboardNav-contentRight .dashboardNav-usericon{margin:0 10px}.dashboardNav .dashboardNav-contentRight .dashboardNav-logouticon{margin:0 30px;cursor:pointer}.dashboardNav .dashboardNav-contentRight .dashboardNav-user{color:#3d5170;font-size:1rem}
.dashboardDownloadBtn{display:flex;justify-content:center;align-items:center;cursor:pointer;margin:2px}.dashboardDownloadBtn .dashboardDownloadBtn-exporttext{font-weight:normal;color:#3c3c3c;font-family:"Montserrat",sans-serif;font-size:1rem;margin:0}
.dashboardSmCard{box-sizing:border-box;width:22%;height:100%;background-color:#fff;box-shadow:0px 0px 16px rgba(0,0,0,.1607843137);border-radius:15px;display:flex;flex-direction:column;justify-content:center;align-items:center;margin:0 10px;padding:20px 0 10px}.dashboardSmCard .dashboardSmCard-text{font-size:1.2rem;color:#3d5170;font-family:"Montserrat",sans-serif;margin:0 0 5px}.dashboardSmCard .dashboardSmCard-value{font-size:1.9rem;color:#ff8300;font-family:"Montserrat",sans-serif;margin:5px}.dashboardSmCard:nth-child(2) .dashboardSmCard-value{color:#00ff8e}.dashboardSmCard:nth-child(3) .dashboardSmCard-value{color:#00d1ff}.dashboardSmCard:nth-child(4) .dashboardSmCard-value{color:#0076ff}
.dashboardFilter{align-self:flex-start;display:flex;flex-direction:column;justify-content:flex-start;height:100%;align-items:flex-start;padding:0 0 0 10px}.dashboardFilter .dashboardFilter-title{font-size:1.5rem;font-family:"Montserrat",sans-serif;color:#3c3c3c}.dashboardFilter .dashboardFilter-title:nth-child(1){margin-top:2px}.dashboardFilter .dashboardFilter-title:nth-child(2){margin-bottom:0}.dashboardFilter .dashboardFilter-btns{display:flex;margin:0 0 0}.dashboardFilter .dashboardFilter-btns .dashboardFilter-geral,.dashboardFilter .dashboardFilter-btns .dashboardFilter-geral_active,.dashboardFilter .dashboardFilter-btns .dashboardFilter-unity,.dashboardFilter .dashboardFilter-btns .dashboardFilter-unity_active{font-family:"Montserrat",sans-serif;font-size:1rem;width:7vw;height:3vh;display:flex;align-items:center;justify-content:center;cursor:pointer}.dashboardFilter .dashboardFilter-btns .dashboardFilter-geral{border:1px solid #d2d8e2;background-color:#fff;border-radius:6px 0 0 6px}.dashboardFilter .dashboardFilter-btns .dashboardFilter-geral_active{color:#fff;border:1px solid #d2d8e2;background-color:#007bff;border-radius:6px 0 0 6px}.dashboardFilter .dashboardFilter-btns .dashboardFilter-unity{background-color:#fff;border:1px solid #d2d8e2;border-radius:0 6px 6px 0}.dashboardFilter .dashboardFilter-btns .dashboardFilter-unity_active{color:#fff;border:1px solid #d2d8e2;background-color:#007bff;border-radius:6px 0 0 6px;border-radius:0 6px 6px 0}.dashboardFilter .dashboardFilter-btns .dashboard-selectunity{font-family:"Montserrat",sans-serif;font-size:1rem;padding-left:5px;margin:0 15px;background-color:#fff;width:15vw;height:3vh;border:1px solid #d2d8e2;border-radius:6px}
.DashboardCardSubscribers{box-sizing:border-box;width:47.5%;height:100%;background-color:#ff8300;box-shadow:0px 0px 16px rgba(0,0,0,.1607843137);border-radius:15px;display:flex;flex-direction:column;justify-content:center;align-items:center;margin:0 10px 0 50px}.DashboardCardSubscribers .DashboardCardSubscribers-text{font-size:1.2rem;color:#fff;font-family:"Montserrat",sans-serif;margin:5px}.DashboardCardSubscribers .DashboardCardSubscribers-value{font-size:1.9rem;color:#fff;font-family:"Montserrat",sans-serif;margin:5px}
.dashboardRankGeral{font-family:"Montserrat",sans-serif;color:#3c3c3c;width:32%;height:48vh;background-color:#fff;box-shadow:0px 0px 16px rgba(0,0,0,.1607843137);border-radius:15px;display:flex;flex-direction:column;justify-content:flex-start;overflow:hidden}.dashboardRankGeral .dashboardRankGeral-titleArea{font-size:1rem;display:flex;padding-left:30px}.dashboardRankGeral .dashboardRankGeral-headerArea{font-family:"Montserrat",sans-serif;display:flex;flex-direction:row;width:100%;height:35px;align-items:center;padding:0 15px 0 15px;background-color:#fbfbfb;border-top:1px solid #c4c4c4}.dashboardRankGeral .dashboardRankGeral-headerArea .dashboardRankGeral-headerPosition{width:10%;height:100%;display:flex;justify-content:center;align-items:center;margin-right:5px}.dashboardRankGeral .dashboardRankGeral-headerArea .dashboardRankGeral-headerName,.dashboardRankGeral .dashboardRankGeral-headerArea .dashboardRankGeral-headerCoins{width:19%;height:100%;display:flex;justify-content:center;align-items:center;margin:0}.dashboardRankGeral .dashboardRankGeral-headerArea .dashboardRankGeral-headerUnity{width:35%;height:100%;display:flex;justify-content:center;align-items:center;margin:0}.dashboardRankGeral .dashboardRankGeral-contentArea{height:72%;display:flex;flex-direction:column;justify-content:flex-start;overflow-y:auto;overflow-x:hidden}#sentinel{background-color:red;width:100%;height:5px}
.dashboardRankLine{font-family:"Montserrat",sans-serif;display:flex;flex-direction:row;width:100%;height:40px;align-items:center;padding:0 15px 0 15px;background-color:#fff;border-top:1px solid #c4c4c4}.dashboardRankLine .dashboardRankLine-Position{width:10%;height:100%;display:flex;justify-content:center;align-items:center;font-weight:bold;margin-right:5px;font-size:1.5rem}.dashboardRankLine .dashboardRankLine-Name,.dashboardRankLine .dashboardRankLine-Coins{width:20%;height:100%;display:flex;justify-content:center;align-items:center;margin:0}.dashboardRankLine .dashboardRankLine-Name{padding-left:5px}.dashboardRankLine .dashboardRankLine-Unity{width:35%;height:100%;display:flex;justify-content:center;align-items:center;margin:0}.dashboardRankLine .dashboardRankLine-Name .dashboardRankLine-Sect,.dashboardRankLine .dashboardRankLine-Unity .dashboardRankLine-Sect,.dashboardRankLine .dashboardRankLine-Coins .dashboardRankLine-Sect{font-weight:normal;font-size:1rem;text-overflow:ellipsis;white-space:nowrap;overflow:hidden}.dashboardRankLine:nth-child(1) .dashboardRankLine-Position,.dashboardRankLine:nth-child(2) .dashboardRankLine-Position,.dashboardRankLine:nth-child(3) .dashboardRankLine-Position{color:#ff6505}
.dashboardRankUnit{font-family:"Montserrat",sans-serif;color:#3c3c3c;width:100%;height:48vh;background-color:#fff;box-shadow:0px 0px 16px rgba(0,0,0,.1607843137);border-radius:15px;display:flex;flex-direction:column;justify-content:flex-start;overflow:hidden}.dashboardRankUnit .dashboardRankUnit-titleArea{font-size:.6rem;display:flex;padding-left:30px}.dashboardRankUnit .dashboardRankUnit-headerArea{font-family:"Montserrat",sans-serif;display:flex;flex-direction:row;width:100%;height:35px;align-items:center;padding:0 15px 0 15px;background-color:#fbfbfb;border-top:1px solid #c4c4c4}.dashboardRankUnit .dashboardRankUnit-headerArea .dashboardRankUnit-headerPosition{width:10%;height:100%;display:flex;justify-content:center;align-items:center;margin-right:5px}.dashboardRankUnit .dashboardRankUnit-headerArea .dashboardRankUnit-headerName,.dashboardRankUnit .dashboardRankUnit-headerArea .dashboardRankUnit-headerCoins{width:20%;height:100%;display:flex;justify-content:center;align-items:center;margin:0}.dashboardRankUnit .dashboardRankUnit-headerArea .dashboardRankUnit-headerUnity{width:35%;height:100%;display:flex;justify-content:center;align-items:center;margin:0}.dashboardRankUnit .dashboardRankUnit-contentArea{height:72%;display:flex;flex-direction:column;justify-content:flex-start;overflow-y:auto;overflow-x:hidden}.dashboardRankUnit .dashboardRankUnit-empty{margin-top:50px;align-self:center;justify-self:center;color:#ff6505;font-family:"Montserrat",sans-serif}
.dashboardChart{box-sizing:border-box;overflow:hidden;display:flex;flex-direction:column;align-items:center;justify-content:space-between;height:100%;padding-bottom:5%}.dashboardChart .dashboardChart-title{padding-left:15px;color:#3c3c3c;width:100%;height:30%;display:flex;justify-content:flex-start;align-items:center;font-weight:bold;font-size:1rem;font-family:"Montserrat",sans-serif;border-bottom:1px solid rgba(230,230,230,.9921568627);margin-bottom:10px}.dashboardChart-chart{height:13.5vw !important;width:13.5vw !important;max-height:90% !important;max-width:100% !important}
.login{background-color:#f0f0f0;display:flex;flex-direction:row;width:100vw;height:100vh}.login .login-left{display:flex;justify-content:center;align-items:center;flex-direction:column;width:50vw;height:100vh;color:#fff;font-family:"Montserrat",sans-serif;background:url(/static/media/bg-dashboard.b533b2bb.png);background-position:center;background-size:cover}.login .login-left .login-bgleft{width:90%}.login .login-right{display:flex;flex-direction:column;justify-content:center;align-items:center;width:50vw;height:100vh}.login .login-right .login-title{font-size:1.4rem;color:#3c3c3c;font-family:"Montserrat",sans-serif}.login .login-right .login-nameinput,.login .login-right .login-passwordinput{width:65%;margin-bottom:30px}.login .login-right .login-nameinput ::before,.login .login-right .login-passwordinput ::before{border-color:#3c3c3c}.login .login-right .login-error{color:red;font-family:"Montserrat",sans-serif;font-size:.8rem}.login .login-right .login-submit{padding:0 25px;display:flex;justify-content:space-around;align-items:center;margin-top:20px;width:200px;height:48px;background:#3c3c3c;border-radius:14px;color:#fff;font-size:.8rem;font-family:"Montserrat",sans-serif;font-weight:bold;cursor:pointer;border:none !important}.login .login-right .login-submit img{width:10px}
.loadLogin{position:absolute;z-index:10;margin:0;box-sizing:border-box;width:50vw;height:100vh;background-color:rgba(240,240,240,.6588235294);display:flex;justify-content:center;align-items:center}
