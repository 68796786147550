@import '../../Styles/variables';

.dashboardFilter {
    align-self: flex-start;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   height: 100%;
   align-items: flex-start;
   padding: 0 0 0 10px;
    .dashboardFilter-title {
        font-size: 1.5rem;
        font-family: $--primary-font;
        color: #3C3C3C;    
        
    }
    .dashboardFilter-title:nth-child(1) {
       margin-top: 2px;
    }
    .dashboardFilter-title:nth-child(2){margin-bottom: 0;}

    .dashboardFilter-btns {
        display: flex;
        margin: 0 0 0;
       
        .dashboardFilter-geral,.dashboardFilter-geral_active,.dashboardFilter-unity ,.dashboardFilter-unity_active{
            font-family: $--primary-font;
            font-size: 1rem;
            width: 7vw;
            height: 3vh;
            display: flex;
            align-items: center;
            justify-content: center; 
            cursor: pointer;
        }
        .dashboardFilter-geral {   
            border: 1px solid #D2D8E2;       
            background-color: #fff;   
            border-radius: 6px 0 0 6px;    
        }

        .dashboardFilter-geral_active {           
            color: #fff;
            border: 1px solid #D2D8E2;
            background-color: #007BFF;   
            border-radius: 6px 0 0 6px;      
        }

        .dashboardFilter-unity {           
            background-color: #fff;
            border: 1px solid #D2D8E2;  
            border-radius:  0  6px  6px 0 ;  
                   
        }

        .dashboardFilter-unity_active {   
            color: #fff;
            border: 1px solid #D2D8E2;
            background-color: #007BFF; 
            border-radius: 6px 0 0 6px;  
            border-radius:  0  6px  6px 0 ;         
        }

        .dashboard-selectunity {
            font-family: $--primary-font;
            font-size: 1rem;
            padding-left: 5px;
            margin: 0 15px;       
            background-color: #fff;
            width: 15vw;
            height: 3vh;
            border: 1px solid #D2D8E2;
            border-radius: 6px;
           
        }

    }
}