@import '../../Styles/variables';

.dashboardNav {
    box-sizing: border-box;
    display: flex;
    width: 100vw;
    height: 80px;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 20px #00000015;
    padding: 0 0 0 20px;
    margin-bottom: 20px;

    .dashboardNav-contentLeft {
        padding: 0 20px 0 0;
        height: 100%;
        display: flex;
        width: 80%;
        justify-content: space-between;
        align-items: center;

        .dashboardNav-logoArea {
            display: flex;
            justify-self: center;
            align-items: center;
        }

        .dashboardNav-logo {
            width: 110px;
            margin: 0 10px;
        }

        .dashboardNav-title {
            font-family: $--primary-font;
            font-weight: bold;
            font-size: 1.5rem;
            color: #3D5170;
            margin: 0 10px;
        }
    }

    .dashboardNav-contentRight {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15vw;
        height: 100%;
        border-left: 1px solid #8C98AB;

        .dashboardNav-usericon {
            margin: 0 10px;
        }

        .dashboardNav-logouticon {      
            margin: 0 30px;
            cursor: pointer;
        }  
      

        .dashboardNav-user {
            color: #3D5170;
            font-size: 1rem;

        }
    }
}