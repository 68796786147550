@import '../../Styles/variables';

.dashboardDownloadBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 2px;

    .dashboardDownloadBtn-exporttext {
        font-weight: normal;
        color: #3C3C3C;
        font-family: $--primary-font;
        font-size: 1rem;
        margin: 0;
    }
}