@import '../../Styles/variables';

.dashboardSmCard {
    box-sizing: border-box;
    width: 22%;
    height: 100%;
    background-color: #FFF;
    box-shadow: 0px 0px 16px #00000029;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    padding: 20px 0 10px;    

    .dashboardSmCard-text {
        font-size: 1.2rem;
        color: #3D5170;
        font-family: $--primary-font;
        margin:0 0 5px 
    }

    .dashboardSmCard-value {
        font-size: 1.9rem;
        color: #FF8300;
        font-family: $--primary-font;
        margin: 5px
    }

   
}
.dashboardSmCard:nth-child(2) {
    .dashboardSmCard-value {
        color: #00FF8E;
    }
}
.dashboardSmCard:nth-child(3) {
    .dashboardSmCard-value {
        color: #00D1FF;
    }
}
.dashboardSmCard:nth-child(4) {
    .dashboardSmCard-value {
        color: #0076FF;
    }
}


