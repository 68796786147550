.loadLogin {
    position: absolute;
    z-index: 10;
    margin: 0;
    box-sizing: border-box;
    width: 50vw;
    height: 100vh;
    background-color: #f0f0f0a8;
    display: flex;
    justify-content: center;
    align-items: center;
}