@import '../../Styles/variables';

.dashboardRankLine {
    font-family: $--primary-font;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 0 15px 0 15px;
    background-color: #ffffff;
    border-top: 1px solid rgb(196, 196, 196);

    .dashboardRankLine-Position {
        width: 10%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .dashboardRankLine-Name,
    .dashboardRankLine-Coins {         
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    .dashboardRankLine-Name{
        padding-left: 5px;
    }
 
    .dashboardRankLine-Unity {
        width: 35%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .dashboardRankLine-Name,
    .dashboardRankLine-Unity,
    .dashboardRankLine-Coins {
        .dashboardRankLine-Sect {
            font-weight: normal;
            font-size: 1rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
           
        }
    }

}
.dashboardRankLine:nth-child(1),.dashboardRankLine:nth-child(2),.dashboardRankLine:nth-child(3) {
    .dashboardRankLine-Position {
        color: $--primary-color
    }
}